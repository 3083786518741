import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import Seo from "../components/seo";
import bgImg from "../images/Image2.webp";
import bgImg2 from "../images/cleaning-7.webp";

const cleaningServices = () => {
  return (
    <Layout>
      <Seo title="Cleaning Services" />
      {/* header */}
      <section>
        <div
          className="container flex flex-col justify-items-center justify-center w-full"
          style={{ position: `relative` }}
        >
          <div
            className="w-screen bg-cover"
            style={{
              backgroundImage: `url(${bgImg})`,
              minHeight: `300px`,
              minWidth: `100%`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
              opacity: `65%`,
            }}
          ></div>
          <div
            className="w-full justify-items-center align-items-center text-center"
            style={{ position: `absolute`, width: `100vw` }}
          >
            <h1 className="text-4xl lg:text-5xl text-white font-bold text-shadow">
              Cleaning Services
            </h1>
          </div>
        </div>
      </section>


      <hr className="border-gray-300" />

      {/* deep cleaning services */}
      <section>
        <hr className="border-gray-300" />
        <div
          className="lg:flex w-full bg-white p-12"
          style={{ width: `100vw` }}
        >
          
          {/* left column desktop*/}
          <div className=" flex flex-col justify-center lg:w-1/3 lg:px-8 self-center">
            <StaticImage
            src="../images/Deep-Cleaning-Photo-replacement.webp"  
              alt="Wilson Farr Cleaning Services logo"
              className="w-3/4 self-center service-img"
            />


          </div>
          {/* centre column desktop */}
          <div className=" flex flex-col justify-center lg:w-1/3 md:px-6 pt-6 lg:pt-2 items-center self-center">
          <h2 className="text-xl font-semibold text-gray-900">
              Deep Cleaning Service
            </h2>
            <p className=" text-gray-800 pt-2 font-light">
            When your space needs a comprehensive overhaul, our deep cleaning service is  the answer. We go beyond the surface to 
            eliminate dirt, grime, and hidden bacteria. Our team  meticulously cleans every nook and cranny, ensuring a spotless and 
            hygienic environment. 
            </p>
          </div>
          {/* right column desktop*/}
          <div className="flex flex-col justify-center lg:w-1/3 md:px-6 pt-6 lg:pt-2 md:items-center self-center">
            <div className="md:w-1/2 text-left text-gray-800 font-light">
              <h2 className="text-xl font-semibold text-gray-900">Service Details</h2>
              <ul className="list-disc pt-2">
                <li>Thorough cleaning of hard-to-reach areas  </li>
                <li>Detailed dusting and wiping of surfaces including: 
                  <ul className="list-disc pl-8">
                    <li>ceilings</li>
                    <li>light fixtures</li>
                    <li>baseboards</li>
                  </ul>
                </li>
                <li>Cleaning and disinfection of:
                  <ul className="list-disc pl-8">
                    <li>appliances</li>
                    <li>cabinets</li>
                    <li>countertops</li>
                    </ul>  
                </li>  
                <li>Removal of built-up dirt and stains </li>
                <li>Comprehensive bathroom and kitchen cleaning </li>
                <li>Thorough disinfectant floor service </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <hr className="border-gray-300" />

      {/* move in/out cleaning services */}
      <section>
        <hr className="border-gray-300" />
        <div
          className="lg:flex w-full bg-white p-12"
          style={{ width: `100vw` }}
        >
          
          {/* left column desktop*/}
          <div className=" flex flex-col justify-center lg:w-1/3 lg:px-8 self-center">
            <StaticImage
              src="../images/white-room-new-house-with-stuff.webp"
              alt="Wilson Farr Cleaning Services logo"
              className="w-3/4 self-center service-img"
            />


          </div>
          {/* centre column desktop */}
          <div className=" flex flex-col justify-center lg:w-1/3 md:px-6 pt-6 lg:pt-2 items-center self-center">
          <h2 className="text-xl font-semibold text-gray-900">
          Move-In/Move-Out Services
            </h2>
            <p className=" text-gray-800 pt-2 font-light">
            Moving can be a stressful process, but with our move-in/move-out  services, you can focus on settling into your new home or preparing it for the next occupants. Our  efficient team will handle all the cleaning tasks, saving you time and effort. 
            </p>
          </div>
          {/* right column desktop*/}
          <div className="flex flex-col justify-center lg:w-1/3 md:px-6 pt-6 lg:pt-2 md:items-center self-center">
            <div className="md:w-1/2 text-left text-gray-800 font-light">
              <h2 className="text-xl font-semibold text-gray-900">Service Details</h2>
              <ul className="list-disc pt-2">
                <li>Complete cleaning of the entire space  </li>
                <li>Removal of dust, dirt, and debris  </li>
                <li>Deep cleaning of bathrooms and kitchens </li>  
                <li>Floor cleaning, including carpets and hard surfaces  </li>
                <li>Cleaning of windows and windowsills  </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <hr className="border-gray-300" />

      {/* window cleaning services */}
      <section>
        <hr className="border-gray-300" />
        <div
          className="lg:flex w-full bg-white p-12"
          style={{ width: `100vw` }}
        >
          
          {/* left column desktop*/}
          <div className=" flex flex-col justify-center lg:w-1/3 lg:px-8 self-center">
            <StaticImage
              src="../images/Window-Cleaning-photo-replacement.webp"
              alt="Window cleaning services"
              className="w-3/4 self-center service-img"
            />


          </div>
          {/* centre column desktop */}
          <div className=" flex flex-col justify-center lg:w-1/3 md:px-6 pt-6 lg:pt-2 items-center self-center">
          <h2 className="text-xl font-semibold text-gray-900">
          Window Cleaning Services
            </h2>
            <p className=" text-gray-800 pt-2 font-light">
            Let natural light flood your home or office with our professional window  cleaning services. Our skilled 
            window cleaners will remove dirt, streaks, and smudges, ensuring crystal clear windows that enhance the 
            overall appearance of your property.             
            </p>
          </div>
          {/* right column desktop*/}
          <div className="flex flex-col justify-center lg:w-1/3 md:px-6 pt-6 lg:pt-2 md:items-center self-center">
            <div className="md:w-1/2 text-left text-gray-800 font-light">
              <h2 className="text-xl font-semibold text-gray-900">Service Details</h2>
              <ul className="list-disc pt-2">
                <li>Thorough cleaning of interior and exterior windows  </li>
                <li>Removal of dirt, smudges, and fingerprints  </li>
                <li>Attention to window frames and sills </li>  
                <li>Use of high-quality cleaning solutions and equipment </li>
                <li>Safe and efficient cleaning techniques  </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <hr className="border-gray-300" />

      {/* Carpet & Upholstery Shampoo Service */}
      <section>
        <hr className="border-gray-300" />
        <div
          className="lg:flex w-full bg-white p-12"
          style={{ width: `100vw` }}
        >
          
          {/* left column desktop*/}
          <div className=" flex flex-col justify-center lg:w-1/3 lg:px-8 self-center">
            <StaticImage
              src="../images/Image2.webp"
              alt="Wilson Farr Cleaning Services logo"
              className="w-3/4 self-center service-img"
            />


          </div>
          {/* centre column desktop */}
          <div className=" flex flex-col justify-center lg:w-1/3 md:px-6 pt-6 lg:pt-2 items-center self-center">
          <h2 className="text-xl font-semibold text-gray-900">
          Carpet, Upholstery & Rug Cleaning Service
            </h2>
            <p className=" text-gray-800 pt-2 font-light">
            Revitalize your carpets, rugs or upholstery with our top-notch services. Our experienced cleaning professionals will use specialised equipment to extract dirt and stains trapped deep within the fibers, restoring their beauty and extending their lifespan.             </p>
          </div>
          {/* right column desktop*/}
          <div className="flex flex-col justify-center lg:w-1/3 md:px-6 pt-6 lg:pt-2 md:items-center self-center">
            <div className="md:w-1/2 text-left text-gray-800 font-light">
              <h2 className="text-xl font-semibold text-gray-900">Service Details</h2>
              <ul className="list-disc pt-2">
                <li>Deep cleaning of carpets, rugs, and upholstery </li>
                <li>Removal of stains, dirt, and odors  </li>
                <li>Use of safe and effective cleaning techniques </li>  
                <li>Careful attention to different fabric types </li>
                <li>Quick drying for minimal disruption   </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <hr className="border-gray-300" />

      {/* Office Cleaning Services */}
      <section >
        <hr className="border-gray-300" />
        <div
          id="office"
          className="lg:flex w-full bg-white p-12"
          style={{ width: `100vw` }}
          
        >
          
          {/* left column desktop*/}
          <div className=" flex flex-col justify-center lg:w-1/3 lg:px-8 self-center">
            <StaticImage
              src="../images/cleaning-10.webp"
              alt="Wilson Farr Cleaning Services logo"
              className="w-3/4 self-center service-img"
            />


          </div>
          {/* centre column desktop */}
          <div className=" flex flex-col justify-center lg:w-1/3 md:px-6 pt-6 lg:pt-2 items-center self-center">
          <h2 className="text-xl font-semibold text-gray-900">
          Office Cleaning Service
            </h2>
            <p className=" text-gray-800 pt-2 font-light">
            A clean and organized workspace contributes to a productive and professional  atmosphere. Our office cleaning services are tailored to meet the unique cleaning requirements of  businesses, ensuring a pristine environment that leaves a lasting impression on clients and employees             </p>
          </div>
          {/* right column desktop*/}
          <div className="flex flex-col justify-center lg:w-1/3 md:px-6 pt-6 lg:pt-2 md:items-center self-center">
            <div className="md:w-1/2 text-left text-gray-800 font-light">
              <h2 className="text-xl font-semibold text-gray-900">Service Details</h2>
              <ul className="list-disc pt-2">
                <li>Regular cleaning schedules customized to your business needs </li>
                <li>Thorough dusting and sanitization of desks, workstations and common areas </li>
                <li>Cleaning and disinfection of restrooms and kitchenettes  </li>  
                <li>Trash removal and recycling management  </li>
                <li>Floor care, including vacuuming and mopping  </li>
                <li>Window cleaning to enhance natural light and views </li>
                <li>Special attention to high-touch surfaces and germ-prone areas </li>
                <li>Detailed cleaning of conference rooms and reception areas </li>
                <li>Upholstery cleaning for chairs, sofas and other seating </li>
                <li>Maintenance of a clean and presentable lobby and waiting area</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* footer */}
      <section>
        <div
          className="container flex flex-col justify-items-center justify-center w-full"
          style={{ position: `relative` }}
        >
          <div
            className="w-screen bg-cover"
            style={{
              backgroundImage: `url(${bgImg2})`,
              minHeight: `450px`,
              minWidth: `100%`,
              backgroundSize: `cover`,
              backgroundPosition: `center`,
              opacity: `65%`,
            }}
          ></div>
          <div
            className="w-full justify-items-center align-items-center text-center"
            style={{ position: `absolute`, width: `100vw` }}
          >
            <h4 className="text-xl text-white font-bold text-shadow p-10">
            At Wilson & Farr Cleaning Solutions, we are committed to delivering exceptional results and exceeding  your expectations. Trust us to handle all of your cleaning needs with professionalism and reliability.  Contact us today for a sparkling clean space! 
            </h4>
          </div>
        </div>
      </section>

    </Layout>
  );
};

export default cleaningServices;
